import React from 'react';
import './AboutPage.css';


const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About WhoisIP</h1>
      <p>
        WhoisIP is a tool designed to provide users with comprehensive and accurate IP address information. Our mission is to make the complex world of IP addresses accessible and understandable for everyone.
      </p>
      <p>
        Whether you're a network administrator, a developer, or just a curious internet user, WhoisIP is here to help you get the information you need. We provide details such as the ISP, organization, country, region, city, and more associated with an IP address.
      </p>
      <p>
        We are committed to maintaining the highest standards of accuracy and reliability for our data. If you have any questions or feedback, please don't hesitate to contact us.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions, suggestions, or feedback, feel free to email us at: 
        <a href="mailto:info@whoisip.com">info@whoisip.com</a>
      </p>
    </div>
       
  

  );
}

export default AboutPage;
