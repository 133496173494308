import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, makeStyles } from '@material-ui/core';
import './NavBar.css'; // Import CSS file

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    color: 'inherit',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px #000000',
  },
  navLinks: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navLink: {
    marginLeft: theme.spacing(2),
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <div className={`navbar ${classes.root}`}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h4" className={`title ${classes.title}`} component={Link} to="/">
            whoisip
          </Typography>
          <div className={`navLinks ${classes.navLinks}`}>
            <Button color="inherit" className={`navLink ${classes.navLink}`} component={Link} to="/">
              Home
            </Button>
            <Button color="inherit" className={`navLink ${classes.navLink}`}>
              <a href="https://myipview.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                My ip view
              </a>
            </Button>
            <Button color="inherit" className={`navLink ${classes.navLink}`} component={Link} to="/about">
              About
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;