import React, { useState } from 'react';

const QandA = () => {
  const [expandedQuesIndex, setExpandedQuesIndex] = useState(null);

  const toggleQuestion = (index) => {
    setExpandedQuesIndex(expandedQuesIndex === index ? null : index);
  };

  const qaData = [
    {
      question: 'What is a Whois domain lookup?',
      answer: 'A Whois domain lookup allows you to trace the ownership and tenure of a domain name. Similar to how all houses are registered with a governing authority, all domain name registries maintain a record of information about every domain name purchased through them, along with who owns it, and the date till which it has been purchased.More details: https://en.wikipedia.org/wiki/WHOIS'
    },
    {
      question: 'What does the Whois domain database contain?',
      answer: 'The Whois database contains details such as the registration date of the domain name, when it expires, ownership and contact information, nameserver information of the domain, the registrar via which the domain was purchased, etc.'
    },
    {
      question: 'What is a Whois IP lookup?',
      answer: 'An IP is a unique address of a server on the internet. Similar to how a telephone number allows you to connect to a specific phone on the telecom network, similarly, an IP address allows your computer to connect to a specific server on the internet.Domain names and IP numbers are the framework upon which the entire world wide web is built.IP numbers are assigned to networking organisations with a record maintained by governing bodies for each IP number and the organisation to which it has been assigned. A whois IP lookup allows you track the above mentioned details for a domain.'

    },
    {
        question: 'How do I conduct a Whois search?',
        answer: 'Enter the domain or IP address for which you would like to conduct a Whois lookup in the search box above. We will query the appropriate database and provide a recent record.'
      },
      {
        question: 'What steps can I take to ensure my domain privacy is protected?',
        answer: 'Some Registrars offer privacy protection services that allow their customers to mask their actual contact details in the Whois search result in order to prevent their private contact information falling into the hands of spammers and scammers. Get in touch with your Registrar to learn more about your options.'
      },
      {
        question: 'Why are some entries hidden in my Whois Domain lookup?',
        answer: 'If the owner of a Domain name has used the privacy protection services of their Registrar, some details may be hidden within the Whois lookup results. Additionally, some Registries proactively conceal some information in order to comply with local data privacy protection laws.'
      },
      {
        question: 'My information does not match the Whois results, how do I change my Whois information?',
        answer: 'According to the rules established by ICANN, Registrars are expected to maintain the accuracy of the domain name owners contact details in the Whois database. Most Registrars use the details provided by the buyer at the time the Domain was registered.In case your information does not match the Whois results, you can change this information by getting in touch with your Registrar who will be able to assist you in updating your information. Once this information is updated, the Whois record will also update accordingly. On an average, it can take 24-24 hours for this change to reflect in the Whois database.'
      },
    

  ];

  return (
    <div className="qa-section">
      <h2 className="qa-heading">Frequently Asked Questions</h2>
      {qaData.map((qa, index) => (
        <div className="qa" key={index}>
          <div className="question" onClick={() => toggleQuestion(index)}>
            <strong>{qa.question}</strong>
          </div>
          {expandedQuesIndex === index && (
            <div className="answer">
              {qa.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default QandA;
