// HomePage.js
import React, { useState } from 'react';
import WhoisLookup from './WhoisLookup';
import QandA from './QandA'; // Import the QandA component
import './HomePage.css'; // Import the CSS file
import Footer from './Footer';
import ReactGA from 'react-ga';
// Initialize React Ga with your tracking ID
ReactGA.initialize('DKL5SK4733');

const HomePage = () => {
  const [searchMade, setSearchMade] = useState(false);

  const handleSearch = () => {
    setSearchMade(true);
  };

  return (
    <div className={`homepage ${searchMade ? 'search-made' : ''}`}>
      <div className="content">
        <h1>Whoisip Domain Lookup</h1>
        <h3>Whois search for Domain and IP</h3>
        <WhoisLookup onSearch={handleSearch} />
        {searchMade ? null : <QandA />} {/* Render QandA component */}
      </div>
      <Footer /> {/* Render Footer component */}
    </div>
  );
}

export default HomePage;
