// Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file for styling

const Footer = () => {
  return (
    <div className="footer">
      <p>&copy; {new Date().getFullYear()} whoisip. All Rights Reserved.</p>
      
    </div>
  );
}

export default Footer;
