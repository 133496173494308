import React, { useState } from 'react';
import axios from 'axios';
import './WhoisLookup.css';

const WhoisLookup = (props) => {
    const [domain, setDomain] = useState('');
    const [whoisInfo, setWhoisInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        setDomain(event.target.value);
    };

    const handleLookup = async () => {
        try {
            setIsLoading(true);
            const result = await axios.get('https://whoisip-server.onrender.com/whois', {
                params: {
                    domain: domain
                }
            });
            setWhoisInfo(result.data);
            if (typeof props.onSearch === 'function') {
                props.onSearch();
            }
        } catch (error) {
            console.error('Failed to fetch Whois data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderDomainInfo = () => {
        if (!whoisInfo) return null;

        return (
            <div className="domain-info">
                {Object.keys(whoisInfo).map(server => {
                    const info = whoisInfo[server];
                    return (
                        <div key={server} className="server-info">
                            <ul>
                                {info['Domain Name'] && <li><strong>Domain Name:</strong> {info['Domain Name']}</li>}
                                {info['Registrar'] && <li><strong>Registrar:</strong> {info['Registrar']}</li>}
                                {info['Created Date'] && <li><strong>Created On:</strong> {info['Created Date']}</li>}
                                {info['Expiry Date'] && <li><strong>Expires On:</strong> {info['Expiry Date']}</li>}
                                {info['Updated Date'] && <li><strong>Updated On:</strong> {info['Updated Date']}</li>}
                                {info['Registrant Organization'] && <li><strong>Registrant Organization:</strong> {info['Registrant Organization']}</li>}
                                {info['Registrant State'] && <li><strong>Registrant State:</strong> {info['Registrant State']}</li>}
                                {info['Registrant Country'] && <li><strong>Registrant Country:</strong> {info['Registrant Country']}</li>}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="whois-lookup">
            <div className="input-container">
                <input type="text" value={domain} onChange={handleInputChange} placeholder="Enter Domain Name or IP Address" />
                <button onClick={handleLookup} disabled={isLoading}>Lookup</button>
                {isLoading && <div className="loader"></div>}
            </div>
            {whoisInfo && renderDomainInfo()}
            {whoisInfo && (
                <div className="raw-data">
                    <h3>Raw Whois Data</h3>
                    <pre>{JSON.stringify(whoisInfo, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default WhoisLookup;
